<template>
  <v-select
    v-model="status"
    label="Status"
    background-color="input"
    :items="options"
    :disabled="disableStatus"
    outlined
    dense
    hide-details
    :menu-props="{ offsetY: true }"
    @change="updateStatus">
  </v-select>
</template>

<script>
// API
import AdRetails from "@/axios/ad-retails"

export default {
  name: 'SuggestedOrderStatus',
  props: {
    adPlanner: Object,
    store_id: String,
    orderInfo: Object,
    isManager: Boolean
  },
  data () {
    return {
      status: null,
      validStatuses: ['DRAFT', 'SUBMITTED']
    }
  },
  computed: {
    options () {
      if (!this.validStatuses.includes(this.adPlanner.suggested_order_status)) {
        return [...this.validStatuses, this.adPlanner.suggested_order_status]
      } else {
        return this.validStatuses
      }
    },
    disableStatus () {
      const { currentDate, lastOrderDate } = this.orderInfo
      return (currentDate > lastOrderDate) || this.adPlanner.suggested_order_status === 'PROCESSED' || this.isManager === false
    }
  },
  created () {
    this.status = this.adPlanner.suggested_order_status
  },
  methods: {
    async updateStatus() {
      try {
        const payload = {
          store_id: this.store_id,
          ad_id: this.adPlanner.ad_id,
          ad_start_date: this.adPlanner.ad_start_date,
          ad_end_date: this.adPlanner.ad_end_date,
          meat_grid: this.adPlanner.meat_grid,
          status: this.status
        }

        await AdRetails.updateSuggestedAdStatus(payload, this.adPlanner.suggested_order_grid_id)
        this.$emit("updated", this.status)
      } catch (err) {
        this.handleError(err)
      }
    }
  }
}

</script>