var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    attrs: {
      label: "Status",
      "background-color": "input",
      items: _vm.options,
      disabled: _vm.disableStatus,
      outlined: "",
      dense: "",
      "hide-details": "",
      "menu-props": { offsetY: true },
    },
    on: { change: _vm.updateStatus },
    model: {
      value: _vm.status,
      callback: function ($$v) {
        _vm.status = $$v
      },
      expression: "status",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }